import { template as template_cc4aa4a872264246b4bc7a65899f2c8b } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import Phone from './contact/phone';
export interface HeaderSignature {
    Element: HTMLElement;
}
const Header: TOC<HeaderSignature> = template_cc4aa4a872264246b4bc7a65899f2c8b(`
  <header id="header">
    <LinkTo @route="index" class="logo">
      Autoškola Beran
    </LinkTo>

    <nav class="right">
      <Phone class="button alt" />
    </nav>
  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Header;
