import { template as template_b2a62d53f0014ce4aa2191a6ab3a63e2 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface PhoneSignature {
    Element: HTMLAnchorElement;
}
const Phone: TOC<PhoneSignature> = template_b2a62d53f0014ce4aa2191a6ab3a63e2(`
  <a href="tel:+420724237845" class="phone" ...attributes>
    +420 724 237 845
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Phone;
