import { template as template_aca853c79f5f4f0a833457b041433040 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
import Header from '../components/header';
import Footer from '../components/footer';
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface ApplicationRouteSignature {
}
const Template: TOC<ApplicationRouteSignature> = template_aca853c79f5f4f0a833457b041433040(`
  <Header />
  {{outlet}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(Template);
