import { template as template_92d6dff26e7f40c7b456dfb505938bee } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface AddressSignature {
    Element: HTMLDivElement;
}
const Address: TOC<AddressSignature> = template_92d6dff26e7f40c7b456dfb505938bee(`
  <div class="address">
    Horní Chvatliny 15, Zásmuky, 281&nbsp;44
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Address;
