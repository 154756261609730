import { template as template_537d912e30f3423e94dc1058e4439a8d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface EmailSignature {
    Element: HTMLAnchorElement;
}
const Email: TOC<EmailSignature> = template_537d912e30f3423e94dc1058e4439a8d(`
  <a href="mailto:ladislavberan@seznam.cz" class="email">
    ladislavberan@seznam.cz
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Email;
